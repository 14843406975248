import React from "react"
import Fade from "react-reveal/Fade"

const AboutTeamBigCardRight = () => {
  return (
    <Fade bottom cascade>
      <div className="max-w-7xl mx-auto mt-10">
        <div className="bg-lightblack w-full h-full flex flex-row justify-between rounded-xl xxs:flex-col sm:flex-row">
          <div className="flex flex-col justify-between">
            <div className="text-white text-right m-6 font-poppins xxs:text-center sm:text-right">
              <h1 className="text-5xl font-bold">Christian Muller</h1>
              <h3 className="text-lg font-bold opacity-50">Founder</h3>
            </div>
            <p className="text-white p-6 text-xl text-right xxs:text-center sm:text-right">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </p>
          </div>
          <img
            className="w-3/4 object-cover h-96 rounded-l-lg xxs:w-full sm:w-3/4"
            src="https://images.unsplash.com/photo-1528241725452-5a0eff84abe3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=80"
          ></img>
        </div>
      </div>
    </Fade>
  )
}

export default AboutTeamBigCardRight
